<template>
  <div class="wrap-edit">
    <div class="edit-header">
      <span>受理通知单</span>
      <el-button type="primary" @click="print">打印</el-button>
    </div>
    <div class="edit-container">
      <div id="forms" style="padding-left: 100px;padding-right: 100px">
        <h1 style="font-size: 25px;font-weight: 400;margin-bottom:50px;text-align: center">慈溪市自然资源和规划局窗口受理通知单</h1>
        <p class="right-align" style="font-size: 14px;">
          案卷号：<span>{{ form.ajh }}</span>
        </p>
        <div style="display: flex;justify-content: flex-end;font-size: 14px">
          <p>窗口电话：<span>{{ dh }}</span></p>
        </div>
        <div style="font-size: 14px;">
          建设单位：<span>{{ form.jsdw }}</span>
        </div>
        <div style="font-size: 14px;margin-top: 5px">
          所办事项：<span>{{ form.sbsx }}</span>
        </div>
        <div class="body" style="font-size: 14px;margin-top: 5px">
          您于<span style="padding: 10px">{{ form.sbsj }}</span
        >申报的项目：<span style="padding: 10px">{{ form.sbxm }}</span
        >于<span style="padding: 10px">{{ form.slsj }}</span
        >受理{{ sj }}
        </div>
        <p style="font-size: 14px;margin-top:25px">
          注：申请人为企事业单位的，由企事业单位的法定代表人或负责人领取；申请人为个人的，由本人领取；领取时出示本通知书与领取人身份证。
          有特殊情况委托他人领取的，需提交通知书，被委托人的身份证复印件，并出示委托人和被委托人的身份证原件。
        </p>
        <p style="font-size: 14px;">
          欢迎访问中文网址http://fwzx.cixi.gov.cn，根据受理编号可以查询到办件的办理进度，并对办结的办件给予评价。
        </p>
        <p class="right-align" style="margin-right: 100px;margin-top: 30px;font-size: 14px">
          经办人：<span>{{ form.jbr }}</span>
        </p>
        <p class="right-align" style="margin-right: 100px;font-size: 14px">（盖章）</p>
      </div>
    </div>
  </div>
</template>

<script>
import print from "print-js";

export default {
  name:'sltzd',
  data() {
    return {
      form: {
        ajh: "",
        jsdw: "",
        sbsx: "",
        sbsj: "",
        slsj: "",
        sbxm: "",
        jbr: "",
      },
      sk: '',
      sj: '',
      dh: '',
    };
  },
  methods: {
    async fetch(Id) {
      try {
        const sbsxList = await this.getsbsxList();
        await this.$ajax
            .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getlahz?Id=" + Id)
            .then((response) => {
              if (response.data.IsSuccess) {
                const data = response.data.Data;
                this.form.ajh = data.AJH;
                this.form.jsdw = data.JSDW;
                this.form.sbsx = sbsxList[data.SBLX];
                this.form.sbxm = data.XMMC;
                this.form.sbsj = this.form.slsj = data.JJRQ?.split("T")[0];
                this.form.jbr = data.JJRXM;
                this.rendersm()
              } else {
                console.log(response.data.ErrorMessage);
              }
            });
      } catch (error) {
        console.log(error);
      }
    },
    getsbsxList() {
      return this.$ajax
          .get(this.$appConfig.apiUrls.flowApi + "/api/WorkFlow/GetWorkFlowList")
          .then(function (response) {
            if (response.data.IsSuccess) {
              return response.data.Data;
            } else {
              console.log(response.data.ErrorMessage);
              return {};
            }
          });
    },
    print() {
      print({
        printable: "forms",
        type: "html",
        scanStyles: false,
        css: "/static/print-style.css",
        documentTitle: "",
      });
    },
    rendersm() {
      let dh = '0574-63961743,63961745,63961746,63961747'
      let sj = '。'
      switch (this.form.sbsx) {
        case '地图审批':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '使用属于国家秘密基础测绘成果审批':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '建设项目用地预审和规划选址审批':
          sj = '，将于2个工作日内办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '临时改变房屋用途审批':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '建设工程设计方案审查':
          sj = '，将于7个工作日内办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '建设工程（含临时建设）规划许可证核发及变更':
          sj = '，将于2个工作日内办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '乡村建设规划许可及变更':
          sj = '，将于2个工作日内办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '建设用地规划许可证':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '划拨国有土地使用权审核':
          sj = '，将于8个工作日内办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '协议出让国有土地使用权审核':
          sj = '，将于8个工作日内办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '临时用地审批':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '设施农用地备案':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '农村集体经济组织兴办企业用地审核':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '乡（镇）村公共设施、公益事业建设用地审核':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '森林植物产地检疫合格证核发':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '林木种子生产经营许可证':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '省内森林植物检疫证核发':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '出省森林植物检疫证核发':
          sj = '，将于当天办结。'
          break
        case '陆生野生动物猎捕许可':
          sj = '，将于当天办结。'
          break
        case '陆生野生动物人工繁育许可(换证)':
          sj = '，将于当天办结。'
          break
        case '陆生野生动物人工繁育许可(新办证)':
          sj = '，将于当天办结。'
          break
        case '陆生野生动物人工繁育许可(变更)':
          sj = '，将于当天办结。'
          break
        case '陆生野生动物及其制品出售、购买、利用许可':
          sj = '，将于当天办结。'
          break
        case '一般林木采伐许可':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '特殊林木采伐许可':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '沿海国际特殊保护林带采伐许可':
          sj = '，将于当天办结。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '占用林地许可':
          sj = '，将于10个工作日内办结。'
          break
        case '临时占用林地许可':
          sj = '，将于10个工作日内办结。'
          break
        case '林业生产占用林地许可':
          sj = '，将于10个工作日内办结。'
          break
        case '建设用地复核验收':
          sj = '，将于9个工作日内办结。'
          break
        case '建设工程竣工规划核实':
          sj = '，将于9个工作日内办结。'
          break
        case '出具规划条件':
          sj = '。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '公示项目认可':
          sj = '。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '危房规划认可':
          sj = '。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '规划红线出具':
          sj = '。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '管道(道口)开挖认可':
          sj = '。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '工程管线铺设认可':
          sj = '。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '土地分割':
          sj = '。'
          break
        case '违法建筑认定':
          sj = '。'
          break
        case '其他特殊审批事项':
          sj = '。'
          dh = '0574-63961740、63961746、63961748、63961810、63961747'
          break
        case '林业生产占用林地许可立项':
          sj = '。'
      }
      this.dh = dh
      this.sj = sj
    }
  },
  mounted() {
    this.fetch(this.$route.query.instanceId);
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: 10px;
}

.edit-container {
  margin-top: 0px;
  background-color: #ffffff;
  border: 1px black solid;
  overflow: hidden;
  padding: 20px;
}

.right-align {
  text-align: right;
}

#form {
  padding: 20px;
  width: calc(100% - 42px);
  margin: auto;
  background-color: white;
  border: 1px black solid;
  overflow: hidden;
}

p {
  margin: 1em 0;
  text-align: justify;
}

.body {
  text-indent: 2em;
}

strong {
  text-decoration: underline;
}
</style>
